import React from 'react'

const Pillers = () => {
  return (
    <div class="pt-24" id="pillars_section">
                  <h3 class="text-3xl text-sp-main-black text-center uppercase pb-8 font-bold ">
                    Our Pillars
                  </h3>
                  <ul
                    role="list"
                    class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
                  >
                    <li class="col-span-1 flex flex-col text-center custom-card-bg rounded-2xl shadow divide-y divide-gray-200 shadow-xl mb-5">
                      <div class="flex-1 flex flex-col p-8">
                        <img
                          class="w-40 mx-auto"
                          src="/Asset/quality.png"
                          alt=""
                        />
                        <h3 class="mt-4 text-sp-main-black text-2xl uppercase font-normal ">
                          Quality
                        </h3>
                        <dl class="mt-1 flex-grow flex flex-col justify-between">
                          <dd class="about-desc text-gray-500 text-base text-center font-light">
                          Striving for excellence in everything we produce and consistently motivating each other to surpass our best is ingrained in our fundamental DNA

                          </dd>
                        </dl>
                      </div>
                    </li>
                    <li class="col-span-1 flex flex-col text-center custom-card-bg rounded-2xl shadow divide-y divide-gray-200 shadow-xl mb-5">
                      <div class="flex-1 flex flex-col p-8">
                        <img
                          class="w-40 mx-auto"
                          src="/Asset/team.png"
                          alt=""
                        />
                        <h3 class="mt-4 text-sp-main-black text-2xl uppercase font-normal ">
                          Team
                        </h3>
                        <dl class="mt-1 flex-grow flex flex-col justify-between">
                          <dd class="about-desc text-gray-500 text-base text-center font-light">
                          We are Creating a culture of mutual support where team members feel valued and encouraged.Together, we operate as a cohesive force.

                          </dd>
                        </dl>
                      </div>
                    </li>
                    <li class="col-span-1 flex flex-col text-center custom-card-bg rounded-2xl shadow divide-y divide-gray-200 shadow-xl mb-5">
                      <div class="flex-1 flex flex-col p-8">
                        <img
                          class="w-40 mx-auto"
                          src="/Asset/joy.png"
                          alt=""
                        />
                        <h3 class="mt-4 text-sp-main-black text-2xl uppercase font-normal ">
                          Joy
                        </h3>
                        <dl class="mt-1 flex-grow flex flex-col justify-between">
                          <dd class="about-desc text-gray-500 text-base text-center font-light">
                          Our mission is to ignite happiness and facilitate meaningful connections among people through universally enjoyable experiences.
                          </dd>
                        </dl>
                      </div>
                    </li>
                  </ul>
                </div>
  )
}

export default Pillers