import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HeroCard from "./components/HeroCard";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutUs from "./components/AboutUs";
import Tiles from "./components/Tiles";
import Pillers from "./components/Pillers";
import { Route, Routes } from "react-router-dom";
import Privacy from "./pages/Privacy";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import Ads from "./pages/Ads";
import FacebookDataDeletion from "./pages/Facebook-data-deletion";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/terms-of-service" element={<Terms />}></Route>
        <Route
          path="/facebook-data-deletion"
          element={<FacebookDataDeletion />}
        ></Route>
        <Route path="/ads/mindfigllp" element={<Ads />}></Route>
      </Routes>
    </>
  );
}

export default App;
