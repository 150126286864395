import React from "react";

const HeroCard = () => {
  return (
    <>
      <header style={{ paddingLeft: 0 }}>
        <div
          className=" text-center bg-image"
          // style={{ backgroundImage: "url('Asset/web9.png')" }}
        >
           <div class="move-down">
            <a href="#about_section" class="uk-icon-button uk-preserve uk-icon" uk-icon="icon: icn-arrow-down" uk-scroll="offset: 80;"><svg class="arrow-icon" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 17L24.4137 15.4138L18.125 21.6912V8H15.875V21.6912L9.5975 15.4025L8 17L17 26L26 17Z" fill="#1E1E1E"></path></svg></a>
        </div>
        </div>
      </header>
    </>
  );
};

export default HeroCard;
