import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./style.less"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
<footer class="footer-section mt-5">
        <div class="container">
            {/* <div class="footer-cta pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>Find us</h4>
                                <span>1010 Avenue, sw 54321, chandigarh</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-phone"></i>
                            <div class="cta-text">
                                <h4>Call us</h4>
                                <span>9876543210 0</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="far fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>Mail us</h4>
                                <span>mail@info.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div class="footer-content pt-5">
                <div class="row">
                    <div class="col-md-12 col-xl-4 col-lg-4 mb-50 footer-logo">
                        <div class="footer-widget footer-logo-wrapper">
                            <div class="footer-logo">
                               <div>
                               <a href="#"><img src="/Asset/logo.png" class="img-fluid" alt="logo"  /></a>
                               </div>
                            <div class="text-light mt-4 ms-2">
                                <h5>
                                    <a href='mailto:hello@mindfig.in' className='text-decoration-none text-light'>hello@mindfig.in</a>
                                </h5>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                            <ul className='footer-links'>
                                <li><a href="#">Home</a></li>
                                <li><a href="#about_section">about</a></li>
                                <li><a href="#our_games">Games</a></li>
                                
                            </ul>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50 footer-icons">
                        {/* <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Subscribe</h3>
                            </div>
                            <div class="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div class="subscribe-form">
                                <form action="#">
                                    <input type="text" placeholder="Email Address" />
                                    <button><i class="fab fa-telegram-plane"></i></button>
                                </form>
                            </div>
                        </div> */}

<div class="footer-social-icon">
                                {/* <span>Follow us</span> */}
                                <a href="#"><i class="fab fa-facebook-f facebook-bg"></i></a>
                                <a href="https://www.artstation.com/mindfig" target='_blank'><i class="fa-brands fa-artstation google-bg"></i></a>
                                <a href="https://www.instagram.com/mindfiggames?igsh=YzVkODRmOTdmMw%3D%3D&utm_source=qr"  target='_blank'><i class="fab fa-instagram twitter-bg"></i></a>
                                <a href="https://www.linkedin.com/company/mindfigllp" target='_blank'><i class="fab fa-linkedin google-bg"></i></a>
                                <a href="https://youtube.com/@MINDFIGLLP?si=8OND2g56nph0cYhE" target='_blank'><i class="fa-brands fa-youtube twitter-bg"></i></a>
                                <a href="https://www.behance.net/mindfig" target='_blank'><i class="fa-brands fa-behance twitter-bg"></i></a>
                            </div>
                    </div>
                    <div className='col-12 text-center text-light footer-terms mb-3'>
                    <Link to="/terms-of-service" className='footer-terms text-decoration-none text-light'>Terms of service</Link>
                    <Link to="/privacy" className='footer-terms text-decoration-none text-light'>Privacy policy</Link>
                    <Link to="/facebook-data-deletion" className='footer-terms text-decoration-none text-light'>Facebook Data Deletion</Link>

                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 text-center text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; {new Date().getFullYear()}, All Right Reserved <a href="#">Mindfig LLP</a></p>
                        </div>
                    </div>
                    {/* <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div class="footer-menu">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Terms</a></li>
                                <li><a href="#">Privacy</a></li>
                                <li><a href="#">Policy</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </footer>

    
    </>
  )
}

export default Footer