import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className=" navbar-bg" style={{height:"auto",padding:"0 1rem"}}>
      <>
        <Navbar.Brand href="#home">
          <img src="/Asset/logo.png" className='header-logo'  />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='custom-toggler' onClick={handleToggle}>
        {!isToggled ?<i class="fa-solid fa-bars" style={{color:"#fff"}} ></i>
        :<i class="fa-solid fa-xmark" style={{color:"#fff"}} ></i>}
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
         
          <Nav className='ms-auto wow bounceIn'>
            <Nav.Link href="#about_section" className="navbar-link">About Us</Nav.Link>
            <Nav.Link href="#our_games" className="navbar-link">Games</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
}

export default Header;