import React from 'react'
import Header from '../components/Header'
import HeroCard from '../components/HeroCard'
import AboutUs from '../components/AboutUs'
import Pillers from '../components/Pillers'
import Tiles from '../components/Tiles'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div>
        <Header />
      <main>
        <HeroCard />

        <div class="lg:overflow-hidden">
          <div class="mx-auto max-w-6_5xl">
            <div class="mx-4 lg:mx-0">
              <div>
                <AboutUs />
                <Tiles />
                <Pillers />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default Home