import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Terms = () => {
    const navigate = useNavigate()
  return (
    <div>
        <div>
        


        <div class="w3-top">
          <div class="w3-bar w3-black w3-card w3-left-align w3-large">
            <h1 class="w3-bar-item w3-padding-large">MINDFIG LLP</h1>
          </div>
        </div>
        
        {/* <!-- Header --> */}
        <header class="w3-container w3-center" style={{padding: "80px 0 0 0"}}>
          <h3 class="w3-margin w3-jumbo privacy-terms-heading">Terms of Service & Privacy Policy</h3>
          <button onClick={()=>navigate("/terms-of-service")} class="w3-button w3-black w3-border w3-border-black w3-padding-large w3-large w3-round-large privacy-term-btn" >Terms of Service</button>
          <button onClick={()=>navigate("/privacy")} class="w3-button w3-white w3-border w3-border-black w3-padding-large w3-large w3-round-large privacy-term-btn" >Privacy Policy</button>
        </header>
        
        {/* <!-- Terms Of Services --> */}
        
        <div class="w3-container">
          <div class="w3-content">
            
            <h3>
                <span style={{fontSize: "24px"}}>MINDFIG LLP TERMS OF SERVICE AGREEMENT</span></h3>
            <p><span style={{fontSize:"16px"}}>Effective Date: December 03, 2023</span></p>
            <p style={{marginRight: "0", marginLeft: "0"}} class="MsoNormal"><span style={{fontSize:"16px"}}>Please also take consider and review MINDFIG LLP’ Privacy Policy that also effects on same date of these Terms of Service.</span></p>
            <p><span style={{fontSize:"16px"}}><strong>TERMS OF SERVICE FOR USE OF WEBSITES AND SERVICES OF MINDFIG GAME SOFTWARE AND MARKETING CORPORATION.</strong></span></p>
            <p><span style={{fontSize:"16px"}}>You should carefully read the following Terms of Service Agreement (“<strong>Agreement</strong>” or “<strong>Terms of Service</strong>”) before using any website (“<strong>Site</strong>”) and mobile game/application and services<span class="Apple-converted-space">&nbsp; </span>(collectively the “<strong>Services</strong>”) of MINDFIG GAME SOFTWARE AND MARKETING CORPORATION. (“<strong>Company</strong>” or “<strong>MINDFIG LLP</strong>”).</span></p>
            <p><span style={{fontSize:"20px"}}><strong>1. TERMS AND BINDING EFFECT</strong></span></p>
            <p><span style={{fontSize:"16px"}}>This is a legally binding Agreement. By using the Site or any Services of MINDFIG LLP, you hereby agree to abide by these Terms of Service, as they may be amended by Company from time to time in its sole discretion and without any notification to you. Company will declare the updated Terms of Service on the Site any time these Terms of Service have been changed or amended. It is your responsibility to review and follow these Terms of Service periodically, and if at any time you find these Terms of Service unacceptable, you must immediately leave the Site and cease all use of the Services and the Site. This Agreement constitutes the entire and only agreement between us and you relating to the subject matter of this Agreement and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the Site, the content, products or services provided by or through the Site.</span></p>
            <p><span style={{fontSize:"16px"}}>If you breach these Terms of Service we may take action against you, including but not limited to terminating your account in Services.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"20px"}}><strong>2. PRIVACY</strong></span></p>
            <p><span style={{fontSize:"16px"}}>To provide the Services, we need information about you, and we only use your information where we have a legal basis to do so. Please refer to our&nbsp;Privacy Policy to help you understand what information we process and how we use it and what choices you have when you use our Services.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>3. AGE OF USERS<span class="Apple-converted-space">&nbsp;</span></strong></span></p>
            <p><span style={{fontSize:"16px"}}>A person under 13 years old are not allowed to use the Services. A person between the age of 13 and 18, requires consent of their parents or legal guardians ("<strong>Parents</strong>") for using Services of the Company. Parents can provide and verify their consent through the&nbsp;Site or through another authorized third-party provider made available through the Service. Where Parental consent is required, MINDFIG LLP hereby recommends that Parents monitor the child’s online activity and use of the Service. A Parent who wishes to recall their previously-provided consent to a child’s access to and use of the Services should disconnect from our Services.<span class="Apple-converted-space">&nbsp; &nbsp;</span></span></p>
            <p><span style={{fontSize:"16px"}}>To the extent permitted under applicable law, MINDFIG LLP rejects and declines any responsibility regarding any activities conducted by a person under 13 years old with or without the permission of a parent. If you are a parent and you give your consent or permission for your child to register for the Services, you thereby agree to the terms relating to use of the Services by your child.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>4. DESCRIPTION OF SITES AND SERVICES</strong></span></p>
            <p><span style={{fontSize:"16px"}}>The Company operates online mobile gaming services, websites and mobile applications that accompany such gaming services.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>5. YOUR OBLIGATIONS</strong></span></p>
            <p><span style={{fontSize:"16px"}}>By registering or using the Sites and Services in any way, you agree to the following:</span></p>
            <p><span style={{fontSize:"16px"}}>a. You agree not to access our Services in an unauthorized manner;</span></p>
            <p><span style={{fontSize:"16px"}}>b. You agree that your use of the Services is at your own risk, and that you will not use the Services to violate any applicable law, regulation, or instructions as outlined in these Terms of Service and you will not encourage or enable any other individual to do so.</span></p>
            <p><span style={{fontSize:"16px"}}>c. You agree not to harass or threaten other players;</span></p>
            <p><span style={{fontSize:"16px"}}>d. You agree not to use any harmful, threatening, abusive, defamatory, obscene, hateful, racially or ethnically offensive language;</span></p>
            <p><span style={{fontSize:"16px"}}>e. If asked by a member of MINDFIG LLP to stop or change a behaviour or action, you will do so;</span></p>
            <p><span style={{fontSize:"16px"}}>f. You agree not to cheat, hack or make any attack on the server in any way;</span></p>
            <p><span style={{fontSize:"16px"}}>g. You agree not to transmit or make available in the Service or Site any advertising, promotional materials or any other forms of solicitation;</span></p>
            <p><span style={{fontSize:"16px"}}>i. You agree not to transmit any material that contains viruses, worms, traps or malicious computer code;</span></p>
            <p><span style={{fontSize:"16px"}}>j. You agree not to disrupt the normal flow of the application and dialogue, or otherwise act in a manner that negatively affects other people’s ability to use the Service;</span></p>
            <p><span style={{fontSize:"16px"}}>k. You agree not to collect, process or store personal data about other people using the Service;</span></p>
            <p><span style={{fontSize:"16px"}}>l. You agree only to use your own credit card with the Service; and</span></p>
            <p><span style={{fontSize:"16px"}}>m. You agree not to use any MINDFIG LLP trademark, service mark or tradename, or any variation or misspelling thereof.</span></p>
            <p><span style={{fontSize:"16px"}}>We may directly terminate or suspend your access to the Services, at our sole discretion, at any time and without notice to you, including if (a) you fail to comply with these Terms of Service; (b) we suspect fraud, cheating, or misuse by you; or (c) we suspect any other unlawful activity associated to your behavior under mobile application.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"20px"}}><strong>6. PAYMENT OBLIGATIONS</strong></span></p>
            <p><span style={{fontSize:"16px"}}><strong>6.1 VIRTUAL ITEMS</strong></span></p>
            <p><span style={{fontSize:"16px"}}>You may purchase with “real world” money a limited, non-transferable, non-sub-licensable, revocable virtual currencies, including but not limited to virtual cash, in-game coins and all other kinds of in-game monies, all for use in the games; (a) virtual in-game items (virtual currency and virtual in-game items referred to collectively as “<strong>Virtual Items</strong>”); and (b) other goods or services within the Services for your personal and non-commercial entertainment. You are only allowed to purchase Virtual Items from authorized partners of MINDFIG LLP, not in any other way.</span></p>
            <p><span style={{fontSize:"16px"}}>All rights, title and interest in and to the Services (including without limitation any games, applications, titles, computer code, themes, objects, characters, names, dialogue, catch phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, moral rights, documentation, in-game chat transcripts and server software) are owned by or licensed to MINDFIG LLP, subject to copyright and other intellectual property rights under applicable laws and international conventions. You understand that MINDFIG LLP has the absolute right to manage, regulate, control, modify or eliminate Virtual Items and may revoke your access to them at any time and for any reason, with or without notice. MINDFIG LLP shall have no liability to you or any third party in the event that MINDFIG LLP exercises any such rights. You have no claim, right, title, proprietary or ownership interest in any of the Virtual Items regardless of any consideration paid for those virtual items.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"16px"}}>Except when explicitly authorized by MINDFIG LLP, (i) transfers of Virtual Items to other users are strictly prohibited; (ii) outside of the Services, you may not buy or sell any Virtual Items for “real world” money or otherwise exchange items for value; and (iii) MINDFIG LLP does not recognize any such purported transfers of Virtual Items or the purported sale, gift or trade in the “real world” of anything that appears in the Services. Any attempt to do any of the foregoing is in violation of these Terms of Services and will result in an automatic termination of your rights to use the Virtual Items and may result in termination of your account. All Virtual Items are forfeited if your account is terminated or suspended for any reason, or if MINDFIG LLP discontinues providing the Services.</span></p>
            <p><span style={{fontSize:"16px"}}>You agree that you will not assert or bring any claim against MINDFIG LLP, its affiliates, directors, officers, employees, agents, service providers or licensors relating to (i) a claim that you own any Virtual Items; or (ii) a claim for an alleged monetary value of Virtual Items lost upon deletion or suspension of your account or modification, termination or expiration of these terms.</span></p>
            <p><span style={{fontSize:"16px"}}>Company either owns or has an exclusive license to all of the content in the mobile applicaitons of MINDFIG LLP. Therefore no one other than Company has any right whatsoever to sell any game and mobile application of MINDFIG LLP content in any manner. In addition, you may not sell in-game items for real money or exchange virtual currency items outside of any game and mobile application of MINDFIG LLP.</span></p>
            <p><span style={{fontSize:"16px"}}>YOU ACKNOWLEDGE THAT YOU HAVE NO PROPERTY RIGHTS WHATSOEVER IN VIRTUAL CURRENCY ITEMS. You further acknowledge that you may not use MINDFIG LLP virtual currency for any purpose outside the games and mobile application of MINDFIG LLP.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"16px"}}><strong>6.2&nbsp;FEES, REFUNDS AND RETURN POLICY</strong></span></p>
            <p><span style={{fontSize:"16px"}}>All sales through the Service are final. You acknowledge and agree that any applicable fees and other charges (including, without limitation, virtual items) are not refundable in whole or in part. You are fully liable for all charges to your account, including but not limited to any unauthorized charges. MINDFIG LLP does not issue any refunds or offer any exchanges of any products purchased on or through the Services.</span></p>
            <p><span style={{fontSize:"16px"}}>IN ADDITION TO THE FOREGOING, YOU ACKNOWLEDGE THAT, EXCEPT AS OTHERWISE SET FORTH UNDER APPLICABLE LAW, YOU WILL NOT BE REFUNDED, RECEIVE MONEY, OR BE OTHERWISE COMPENSATED FOR UNUSED VIRTUAL ITEMS OR OTHER GAME ASSETS WHEN THE SERVICES ARE TERMINATED FOR ANY REASON AND WITHOUT NOTICE OR AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.</span></p>
            <p><span style={{fontSize:"16px"}}>MINDFIG LLP may, at any time, amend the pricing, availability, specifications, content, descriptions or features of the game and mobile application or any products sold through the Services. The inclusion of any products through the Services at a particular time does not imply or warrant that these products will be available at any other time. All such changes will be effective immediately upon posting of such new product prices to the Services.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>7. PROHIBITED USES</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Company imposes certain restrictions on your permissible use of the Site and the Service. You are prohibited from violating or attempting to violate any security features of the Site or Service, including, without limitation,</span></p>
            <p><span style={{fontSize:"16px"}}>(a) accessing content or data not intended for you, or logging onto a server or account that you are not authorized to access; </span><br /><span style={{fontSize:"16px"}}>(b) attempting to probe, scan, or test the vulnerability of the Service, the Site, or any associated system or network, or to breach security or authentication measures without proper authorization; </span><br /><span style={{fontSize:"16px"}}>(c) interfering or attempting to interfere with service to any user, host, or network, including, without limitation, by means of submitting a virus to the Site or Service, overloading, “flooding,” “spamming,” “mail bombing,” or “crashing;” </span><br /><span style={{fontSize:"16px"}}>(d) using the Site or Service to send unsolicited e-mail, including, without limitation, promotions, or advertisements for products or services; </span><br /><span style={{fontSize:"16px"}}>(e) forging any packet header or any part of the header information in any e-mail or in any posting using the Service; </span><br /><span style={{fontSize:"16px"}}>(f) attempting to modify, reverse-engineer, decompile, disassemble, or otherwise reduce or attempt to reduce to a human-perceivable form any of the source code used by Company in providing the Site or Service. Any violation of system or network security may subject you to civil and/or criminal liability.</span></p>
            <p><span style={{fontSize:"16px"}}>Additionally, only MINDFIG Oyun Yazilim ve Pazarlama Anonim Şirketi or its licensees have the right to host the games and mobile application of MINDFIG LLP. Accordingly, you may not host, provide matchmaking services for, or intercept, emulate, or redirect the communication protocols used by MINDFIG LLP as part of any game or mobile application of MINDFIG LLP, regardless of the method used to do so. Such prohibited methods may include, but are not limited to protocol emulation, reverse engineering, modifying the games of MINDFIG LLP, adding components to the games of MINDFIG LLP, or using a utility program to host the games of MINDFIG LLP. Also, the use of any "user interface" for Company games other than the user interface that is Company provides for any particular Company game is prohibited by the Company.</span></p>
            <p><span style={{fontSize:"16px"}}>Furthermore, you agree that you will not (1) modify or cause to be modified any files that are a part of<span class="Apple-converted-space">&nbsp; </span>game of MINDFIG LLP; (2) create or use cheats, “mods”, and/or hacks, or any other third-party software designed to modify the experience of games of MINDFIG LLP; (3) use any third-party software that intercepts, “mines”, or otherwise collects information from or through a game of MINDFIG LLP.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"20px"}}><strong>8. USE OF SOFTWARE</strong></span></p>
            <p><span style={{fontSize:"16px"}}><strong>8.1 USER CONTENT</strong></span></p>
            <p><span style={{fontSize:"16px"}}>When accessing the Site or using the Service, you agree to obey the local law, general principles of law and to respect the intellectual property rights of others. Your use of the Service and the Site is at all times governed by and subject to laws regarding copyright ownership and use of intellectual property. You agree not to upload, download, display, perform, transmit, or otherwise distribute any information or content (collectively, “Content”) in violation of any third party’s copyrights, trademarks, or other intellectual property or proprietary rights. You agree to abide by laws regarding copyright ownership and use of intellectual property, and you shall be solely responsible for any violations of any relevant laws and for any infringements of third-party rights caused by any Content you provide or transmit, or that is provided or transmitted using your User ID. The burden of proving that any Content does not violate any laws or third-party rights rests solely with you. Additionally, in the event that you become aware of or reasonably suspect any infringement of the copyrights or trademarks or Company or any third parties, occurring on any Company Site, you will immediately notify Company to report such infringement. You agree not to upload, download, display, perform, transmit, or otherwise distribute any Content that (a) is libellous, defamatory, obscene, pornographic, abusive, or threatening; or (b) violates any applicable local, state, national, or foreign law or regulation under chat sessions. Company reserves the right to terminate your receipt, transmission, or other distribution of any such material using the Service, and, if applicable, to delete any such material from its servers. Company intends to cooperate fully with any law enforcement officials or agencies in the investigation of any violation of these Terms of Service or of any applicable laws.</span></p>
            <p><span style={{fontSize:"16px"}}><strong>8.2 LIMITED LICENSE TO USE THE SERVICES</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Subject to your agreement and continuing compliance with these Terms of Service and any other relevant MINDFIG LLP policies, MINDFIG LLP grants you a non-exclusive, non-transferable, non-sublicensable, revocable and limited license to access and use the service for your own non-commercial entertainment purposes. You agree not to use the service for any other purpose as Services are provided to you for your personal and non-commercial use only.</span></p>
            <p><span style={{fontSize:"16px"}}>The following restrictions apply to the use of the service:</span></p>
            <ol style={{listStyleType: "lower-alpha"}}>
            <li><span style={{fontSize:"16px"}}>You shall not (or attempt to) purchase, sell, rent or give away your account and you shall use your account only for non-commercial purposes, you shall not create an account using a false identity or information, or on behalf of someone other than yourself;<span class="Apple-converted-space">&nbsp;</span></span></li>
            <li><span style={{fontSize:"16px"}}>You shall not use the Service if you have previously been removed by MINDFIG LLP, or previously been banned from playing any MINDFIG LLP game.</span></li>
            </ol>
            <p><span style={{fontSize:"16px"}}><strong>8.3 LICENSE LIMITATIONS</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Any use of the Service in violation of these License Limitations is strictly prohibited, can result in the immediate revocation of your limited license and may subject you to liability for violations of law.</span></p>
            <p><span style={{fontSize:"16px"}}>You agree that you will not, under any circumstances:</span></p>
            <ol style={{listStyleType: "lower-alpha"}}>
            <li><span style={{fontSize:"16px"}}>Engage in any act that MINDFIG LLP deems to be in conflict with the spirit or intent of the Service or make improper use of MINDFIG LLP support services.</span></li>
            <li><span style={{fontSize:"16px"}}>Use or take part (directly or indirectly) in the use of cheats, exploits, automation software, bots, hacks, mods or any unauthorized third-party software designed to modify or interfere with the service, any MINDFIG LLP game or any MINDFIG LLP game experience.</span></li>
            <li><span style={{fontSize:"16px"}}>Modify or cause to be modified any files that are a part of the service or any MINDFIG LLP game and mobile application without MINDFIG LLP express written consent.</span></li>
            <li><span style={{fontSize:"16px"}}>Attempt to gain unauthorized access to the Service, accounts registered to others or to the computers, Servers, or networks connected to the Service by any means other than the user interface provided by MINDFIG LLP, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Service.</span></li>
            </ol>
            <p><span style={{fontSize:"16px"}}>MINDFIG LLP reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms of Service or the Service itself. MINDFIG LLP reserves the right to take action as a result, which may include terminating your account and prohibiting you from using the Service in whole or in part.</span></p>
            <p><span style={{fontSize:"16px"}}><strong>8.4</strong> <strong>SUSPENSION AND TERMINATION OF ACCOUNT AND SERVICE</strong></span></p>
            <p><span style={{fontSize:"16px"}}>WITHOUT LIMITING ANY OTHER REMEDIES, MINDFIG LLP MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS THEREOF IF YOU ARE, OR MINDFIG LLP SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH ANY OF THESE TERMS OF SERVICE OR FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE OF THE SERVICE, WITH OR WITHOUT NOTICE TO YOU.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"16px"}}>WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR MOBILE APPLICATION, GAMES AND SITES, AND THEIR CONTENT, SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR POLICIES. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>9. COPYRIGHT INFRINGEMENT</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Company has in place certain legally mandated procedures regarding allegations of copyright infringement occurring on the Site or with the Service. Company has adopted a policy that provides for the immediate suspension and/or termination of any Site or Service user who is found to have infringed on the rights of Company or of a third party, or otherwise violated any intellectual property laws or regulations. Company’s policy is to investigate any allegations of copyright infringement brought to its attention. If you have evidence, know, or have a good faith belief that your rights or the rights of a third party have been violated and you want Company to delete, edit, or disable the material in question, you must provide Company with all of the following information: (a) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works; (b) identification of the material that is claimed to be infringed or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit Company to locate the material; (c) information reasonably sufficient to permit Company to contact you, such as an address, telephone number, and if available, an electronic mail address at which you may be contacted. For this notification to be effective, you must provide it to Company’s designated agent at:</span></p>
            <p><span style={{fontSize:"16px"}}>MINDFIG GAME SOFTWARE AND MARKETING CORPORATION.</span></p>
            <p><span style={{fontSize:"16px"}}>Rajkot-360005, Gujarat, India</span></p>
            <p><span style={{fontSize:"16px"}}>hello@mindfig.in&nbsp;</span></p>
            <p><span style={{fontSize:"20px"}}><strong>10. ALLEGED VIOLATIONS</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Company reserves the right to terminate your use of the Service, mobile application and/or the Site. To ensure that Company provides a high quality experience for you and for other users of the Site and the Service, you agree that Company or its representatives may access your account and records on a case-by-case basis to investigate complaints or allegations of abuse, infringement of third party rights, or other unauthorized uses of the Site or the Service in accordance with the terms and provisions of Privacy Policy of MINDFIG LLP. Company does not intend to disclose the existence or occurrence of such an investigation unless required by law, but Company reserves the right to terminate your account or your access to the Site immediately, with or without notice to you, and without liability to you, if Company believes that you have violated any of the Terms of Service, furnished Company with false or misleading information, or interfered with use of the Site or the Service by others.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>11. NO WARRANTIES</strong></span></p>
            <p><span style={{fontSize:"16px"}}>COMPANY HEREBY DISCLAIMS ALL WARRANTIES. THE SERVICE AND INFORMATION FROM OR THROUGH THE SITE ARE PROVIDED “AS IS” AND “AS AVAILABLE.” TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. THE INFORMATION AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. WE AND OUR AFFILIATED PARTIES HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION OR SERVICE.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>12. LIMITATION OF LIABILITY<span class="Apple-converted-space">&nbsp;</span></strong></span></p>
            <p><span style={{fontSize:"16px"}}>TO THE MAXIMUM EXTENT PERMITTED BY LAW, MINDFIG LLP SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT MINDFIG LLP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, MINDFIG LLP SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU PAID TO US FOR THE PURCHASES MADE OVER THE GAMES OF MINDFIG LLP.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"16px"}}>NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF MINDFIG LLP.</span></p>
            <p><span style={{fontSize:"16px"}}>You agree to indemnify, defend and hold MINDFIG LLP (and our officers, directors, agents, subsidiaries, joint ventures and employees) harmless from any claim, demand, damages or other losses, including reasonable attorneys' fees, asserted by any third-party resulting from or arising out of your use of the Service, or any breach by you of these Terms of Service, however the foregoing does not apply if the infringement of rights is not attributable to your intentional or negligent behaviour.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>13. DISCLAIMER AND ACKNOWLEDGEMENT REGARDING GAME DATA</strong></span></p>
            <p><span style={{fontSize:"16px"}}>You hereby acknowledge that by using the Site or the Services that you do not acquire any property rights whatsoever in the Site, Services, Game Data (as defined below), or any status within the Site and Services. To play games created by the Company requires the creation and retention of electronic files, including without limitation player characters, accounts, in-game virtual currency, statistics, user profiles,<span class="Apple-converted-space">&nbsp; </span>etc. ("<strong>Game Data</strong>"), which are stored by Company. While keeping Game Data safe is a priority of Company, note that Company shall not have any liability for the loss of any Game Data for any reason whatsoever.</span><br /><br /><span style={{fontSize:"16px"}}>Specifically, regarding all MINDFIG LLP virtual in-game currencies, YOU HEREBY ACKNOWLEDGE THAT BY PURCHASING OR OTHERWISE OBTAINING IN-GAME CURRENCIES YOU DO NOT ACQUIRE ANY PROPERTY RIGHTS WHATSOEVER IN THE IN-GAME CURRENCIES. MINDFIG LLP IN-GAME CURRENCIES ARE GAME DATA THAT ARE INTEGRAL TO MINDFIG LLP PROVIDING ENTERTAINMENT SERVICES TO ITS USERS.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>14. INDEMNIFICATION<span class="Apple-converted-space">&nbsp;</span></strong></span></p>
            <p><span style={{fontSize:"16px"}}>You agree to indemnify Company for certain of your acts and omissions. You agree to indemnify, defend, and hold harmless Company, its affiliates, officers, directors, employees, consultants, agents, and representatives from any and all third party claims, losses, liability, damages, and/or costs (including but not limited to all attorney fees and costs) arising from your access to or use of the Site, your violation of these Terms of Service, or your infringement, or infringement by any other user of your account, of any intellectual property or other right of any person or entity. Company will notify you promptly of any such claim, loss, liability, or demand, and will provide you with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>15. FORUMS AND COMMUNICATIONS</strong></span></p>
            <p><span style={{fontSize:"16px"}}>"Forum" means a one to one chat area, message board, instant messaging, mobile messaging with other mobile game and mobile application users or e-mail function (including, but not limited to, electronic greeting cards) offered as part of any Services. If you participate in any Forum within the Services, you agree to follow our terms set forth below.</span></p>
            <p><span style={{fontSize:"16px"}}>You acknowledge that anything you submit under the Services by way of any Forum is routed through Company’s servers. Please keep in mind that whenever you give out personal information online, for example, via message boards or chat, that information can be collected and used by people you don't know. We are not able to guarantee the security of any information you disclose online; you make such disclosures at your own risk.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>16. WEBSITE CODE</strong></span></p>
            <p><span style={{fontSize:"16px"}}>All website code and shockwave files associated with the Site or Service are belong to MINDFIG LLP.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>17. TRADEMARK</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Unlawful use of Company trademarks is strictly prohibited. Such trademarks shall include, but are not limited to: “MINDFIG LLP”.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>18. DISPUTES AND GOVERNING LAW</strong></span></p>
            <p><span style={{fontSize:"16px"}}>If a dispute arises between you and MINDFIG LLP, MINDFIG LLP strongly encourages you to first contact MINDFIG LLP directly to seek a resolution by opening a help ticket directly in-game via the help section or contact MINDFIG LLP at&nbsp;<a href="mailto:hello@mindfig.in">hello@mindfig.in</a>. You agree that all disputes between you and MINDFIG LLP shall be governed by the laws of India by a Center Courts of India without regard to conflict of law provisions, and you agree to submit to the jurisdiction of such court.</span></p>
            <p><span style={{fontSize:"20px"}}><strong>19. SEVERABILITY</strong></span></p>
            <p><span style={{fontSize:"16px"}}>If, for whatever reason, a court of competent jurisdiction finds any term or condition in these Terms of Service to be unenforceable, all other terms and conditions will remain unaffected and in full force and effect.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"20px"}}><strong>20. MODIFICATIONS</strong></span></p>
            <p><span style={{fontSize:"16px"}}>Company may, in its sole discretion and without prior notice, (a) revise these Terms of Service; (b) modify the Site and/or the Service; and (c) discontinue the Site and/or Service at any time. Company shall post any revision to these Terms of Service to the Site, and the revision shall be effective immediately on such posting. You agree to review these Terms of Service and other online policies posted on the Site periodically to be aware of any revisions.<span class="Apple-converted-space">&nbsp;</span></span></p>
            <p><span style={{fontSize:"20px"}}><strong>21. FORCE MAJEURE<span class="Apple-converted-space">&nbsp;</span></strong></span></p>
            <p><span style={{fontSize:"16px"}}>MINDFIG LLP shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of MINDFIG LLP, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond MINDFIG LLP’ control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, pandemic, energy, labor or materials.</span></p>
            <p><span style={{fontSize: "18px"}}>BY USING THE SERVICE OR ACCESSING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</span></p>
              {/* </li>
            </ul> */}
          </div>
        </div>
        
        
            </div>
    </div>
  )
}

export default Terms