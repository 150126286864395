import React from 'react'

const Ads = () => {
    
    console.log('process.env.REACT_APP_ADS', process.env.REACT_APP_ADS)
  return (
    <div>{process.env.REACT_APP_ADS}</div>
  )
}

export default Ads