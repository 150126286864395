import React from 'react'

const AboutUs = () => {
  return (
    <div>
         <div  id="about_section">
                  <div class="z-0 w-full absolute overflow-hidden -left-px md:-mt-36 items-center">
                    <svg
                      style={{ left: 0, top: 0 }}
                      class="w-full opacity-40 hidden md:block"
                      height="484"
                      viewBox="0 0 1440 484"
                      preserveAspectRatio="none"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L180.826 125.054L360.652 1L540.454 125.054L720.28 1L900.106 125.054L1079.93 1L1259.76 125.054L1439.58 1"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 90.4675L180.826 214.521L360.652 90.4675L540.454 214.521L720.28 90.4675L900.106 214.521L1079.93 90.4675L1259.76 214.521L1439.58 90.4675"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 179.96L180.826 304.013L360.652 179.96L540.454 304.013L720.28 179.96L900.106 304.013L1079.93 179.96L1259.76 304.013L1439.58 179.96"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 269.427L180.826 393.481L360.652 269.427L540.454 393.481L720.28 269.427L900.106 393.481L1079.93 269.427L1259.76 393.481L1439.58 269.427"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 358.919L180.826 482.973L360.652 358.919L540.454 482.973L720.28 358.919L900.106 482.973L1079.93 358.919L1259.76 482.973L1439.58 358.919"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                    </svg>
                    <svg
                      style={{ left: 0, top: 0 }}
                      class="w-full opacity-40 block md:hidden"
                      height="484"
                      viewBox="0 0 721 480"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.42725L180.826 125.481L360.652 1.42725L540.454 125.481L720.28 1.42725"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 87L180.826 211.054L360.652 87L540.454 211.054L720.28 87"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 178L180.826 302.054L360.652 178L540.454 302.054L720.28 178"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 269L180.826 393.054L360.652 269L540.454 393.054L720.28 269"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M1 355L180.826 479.054L360.652 355L540.454 479.054L720.28 355"
                        stroke="#D1D7DA"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>
                  <div class="rounded-2xl bg-sp-main-purple px-8 lg:px-24 py-10 shadow-xl about-bg">
                    <div class="ml-3">
                      <h3 class="text-3xl text-white text-center uppercase font-bold  about-title">
                        About Us
                      </h3>
                      <div class="mt-4 text-md text-gray-300 text-center font-light">
                        <p className='about-desc'>
                          Mindfig stands as a mobile game and AR/VR development
                          company, forged by a collective of visionaries who
                          value creativity, innovation, and Attention to detail.
                          We believe that when individuals come together with a
                          shared mission and the liberty to unfold their
                          talents, something enchanting happens. The ultimate
                          outcome is the birth of a game destined to captivate
                          players for years on end.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  )
}

export default AboutUs