import React from 'react'

const FacebookDataDeletion = () => {
  return (
    <div>
     <div class="w3-top">
  <div class="w3-bar w3-black w3-card w3-left-align w3-large">
    <h1 class="w3-bar-item w3-padding-large">MINDFIG LLP</h1>
  </div>
</div>
  <header class="w3-container w3-center " style={{padding: "80px 0 0 0"}}>
  <h1><b>Facebook Data Deletion Instructions</b></h1>
</header>
       
<div style={{display:"flex",justifyContent:"center"}}>

<div>
<h2>How to remove your data</h2>
<p>MINDFIG LLP uses a facebook login app to authenticate our users. MINDFIG LLP does not save your personal data in our server.</p>
<p></p>
<p>According to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL</p>
<p></p>
<p></p>
<p>If you want to delete your activities for MINDFIG LLP'S facebook login app, you can remove your information by following these steps:</p>
<ul>
    <li>Go to your Facebook Account’s “Settings & Privacy”.</li>
    <li>Click “Settings”</li>
    <li>Then click “Security and Login”.</li>
    <li>Look for “Apps and Websites” and you will see all of the apps and websites you have linked with your Facebook account.</li>
    <li>Search and Click “MINDFIG” in the search bar.</li>
    <li>Scroll and click “Remove”.</li>
</ul>
</div>
</div>
<footer class="w3-container w3-black w3-padding-8 w3-center d-flex align-items-center justify-content-center py-3"> 
  <p>Powered by <a href="" target="_blank" className='text-light text-decoration-none'>MINDFIG LLP</a></p>
</footer>
    </div>
  )
}

export default FacebookDataDeletion